




import { Component, Vue } from 'vue-property-decorator'
import { Logs } from '@/api'
import { ShopModule } from '@/store/modules'

@Component({
  components: {
    LogsTable: () => import('./LogsTable.vue')
  }
})
export default class UserShopLogs extends Vue {
  @ShopModule.State initialShopItems
  data = []

  async mounted() {
    const userId = this.$route.params.id
    const shopLogs = userId
      ? await Logs.getUserShopLogsById(userId)
      : await Logs.getShopLogs()

    this.data = shopLogs.map(e => {
      return {
        orderId: e.id,
        item: this.initialShopItems.find(item => item.id == e.packId)?.name || e.packId,
        quantity: e.quantity,
        price: e.price,
        date: new Date(e.dateTime).toUTCString(),
        deliveredOn: e.characterId
      }
    })
  }
}
